<template>
  <div class="us">
    <div class="container">
      <div class="about-title">
        <h1 class="primary-title">关于我们</h1>
        <h2 class="secondary-title">ABOUT US</h2>
      </div>
      <div class="about-content">
        <div class="about-item-content">
          <div class="about-item-title">概述</div>
          <div class="about-item-body">
            <p>
              嗨喵悦动为上海嗨喵网络科技有限公司旗下品牌，主要致力于活动现场为主办方提供大屏幕互动服务
            </p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">品牌简介</div>
          <div class="about-item-body">
            <p>
              嗨喵悦动提供的互动服务涵盖：3D签到、签到簿 、倒计时 、弹幕上墙
              、霸屏 、抢宏包 、拔河 、赛车 、数钱、幸运夺宝、摇一摇、宏包雨
              、扭一扭 、转一转、开宝箱、、幸运宏包墙、抽奖
              、投票、猜明星、猜成语、猜英雄、猜歌名、火眼金睛等现场互动功能。
            </p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">发展历程</div>
          <div class="about-item-body">
            <p>2020年09月03日，上海嗨喵网络科技有限公司成立</p>
            <p>2020年10月10日，获得数百万元天使投资</p>
            <p>
              2020年11月20日，嗨喵悦动，官网、嗨喵互动、中台、嗨喵助手、桌面程序启动内测
            </p>
            <p>
              2020年12月02日，局部开放公测，上线当天注册用户突破3000人，参见
              <strong
                ><a
                  href="https://mp.weixin.qq.com/s?__biz=MzkxNjEzMzAxNw==&mid=2247483668&idx=1&sn=7c580ac79c7483e83094450cbc9ddd07&chksm=c155c4e2f6224df4ba5f11b27dc62c4c6c5ec56214e8ab399233c4ca2a6398ab68da92814eae&token=1103916425&lang=zh_CN"
                >
                  《感恩·遇见》</a
                ></strong
              >
            </p>
            <p>
              2020年12月17日，获得第二届安徽主持人交流峰会邀请，嗨喵产品安徽推介会，参见<strong
                ><a
                  href="https://mp.weixin.qq.com/s?__biz=MzkxNjEzMzAxNw==&mid=2247483881&idx=1&sn=0c9b382ad3d49fab783d5a47dc55e3f1&chksm=c155c41ff6224d09cdd2b0e8f5bf0681de1c66ab967dabed2ee3a5037605b68adca38b8a5714&token=1103916425&lang=zh_CN"
                  >《嗨喵悦动祝贺：2020年第二届安徽主持人交流峰会取得圆满成功》</a
                ></strong
              >
            </p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">适用场景</div>
          <div class="about-item-body">
            <p>浪漫婚礼</p>
            <p>生日宴会</p>
            <p>年会庆典</p>
            <p>企业营销</p>
            <p>会议论坛</p>
            <p>商业活动</p>
            <p>校园户外</p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">功能更新</div>
          <div class="about-item-body">
            <p>2020年12月03日，新增猜明星小游戏；</p>
            <p>2020年12月05日，新增888钻石宝箱；</p>
            <p>2020年12月06日，新增企业版签到簿、优化二维码记忆功能；</p>
            <p>
              2020年12月09日，新增自定义Logo、新增自定义签到语、新增猜成语游戏、新增投票功能；
            </p>
            <p>2020年12月10日，新增手机互动端摇一摇、宏包雨、数钞票排行榜；</p>
            <p>
              2020年12月18日，新增转一转游戏、新增猜英雄游戏、送礼物弹幕改版；
            </p>
            <p>
              2020年12月19日，新增宏包功能，开宝箱宏包、流水宏包、邀请宏包、新人宏包；
            </p>
            <p>
              2020年12月24日，新增猜歌名游戏、新增火眼金睛游戏、新增巨幕抽奖；
            </p>
            <p>敬请期待......</p>
          </div>
        </div>
      </div>
    </div>
    <myFooter />
  </div>
</template>

<script>
import myFooter from '@/components/myFooter.vue';

export default {
  name: 'us',
  // metaInfo: {
  //   title: '嗨喵悦动 - 嗨喵悦动登录_嗨喵悦动大屏互动',
  //   meta: [
  //     {
  //       name: 'keywords',
  //       content:
  //         '嗨喵悦动,嗨喵悦动大屏互动,嗨喵悦动官网,嗨喵悦动登录,微信墙,微信互动,现场互动,嗨现场',
  //     },
  //     {
  //       name: 'description',
  //       content:
  //         '嗨喵悦动是一款免费便捷炫酷的活动大屏幕现场互动软件，适用于企业年会、酒吧、婚礼、商业庆典峰会等活动现场，包括微信上墙送祝福、扫码签到、抽奖投票、摇一摇宏包雨等有趣的大屏互动游戏，让活动更精彩！',
  //     },
  //   ],
  // },
  components: { myFooter },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  position: relative;
}
.banner > .img {
  width: 100%;
  height: 50%;
  background-color: #f9f9f9;
  vertical-align: middle;
  border: 0;
}

.about-title {
  top: 15%;
  padding: 15px 37.5px;
  width: 100%;
  color: #000;
  position: relative;
}

.primary-title {
  font-size: 30px;
}

.secondary-title {
  margin-top: 7.5px;
  padding-left: 22.5px;
  font-size: 15px;
  font-weight: 500;
  font-family: "Times New Roman", serif;
}

.container {
  position: relative;
}

.about-content {
  margin: 0px auto 0;
  padding: 22.5px 37.5px;
  width: 100%;
  max-width: 817.5px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 15px 11.25px -3.75px #d3cfc5;
}

.about-item-content {
  margin-top: 7.5px;
}

.about-item-title {
  color: #ff4141;
  font-size: 12px;
  margin-bottom: 15px;
  font-weight: bolder;
}

.about-item-body {
  margin-bottom: 22.5px;
  line-height: 1.5;
  text-indent: 2em;
}

p {
  margin: 0 0 7.5px;
}
</style>
